import React from "react"
import { PageProps } from "gatsby"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

import SEO from "../../components/seo"
import { GridContainer } from "../../components/grid"
import { PhotoHero, SimpleTitle } from "../../components/heros"
import { CardsContainer, SecondaryCard } from "../../components/cards"
import { PrimaryLink, SecondaryLink } from "../../components/links"
import { NavigationDetailCTA } from "../../components/ctas"
import { BaseContainer, VideoContainer } from "../../components/containers"
import { H2, H4 } from "../../components/text"

const ApproachContainer = styled(BaseContainer)`
  display: grid;
  gap: 2rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    grid-column: 2 / span 10;
  }
`

const ParagraphContainer = styled.div`
  // border: 4px solid red;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
  }
`

const TitleContainer = styled.div`
  margin-bottom: 1rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-bottom: 0;
    grid-column: 9 / span 2;
    grid-row: 1;
  }
`

const TopBorder = styled.div`
  height: 8px;
  width: 4rem;
  background: ${({ theme }) => theme.colors.beukelaarGreenLight};
  margin-bottom: 1rem;
`

const StyledParagraph = styled.p`
  grid-column: 1 / span 7;
`

interface ParagraphProps {
  title?: string
  className?: string
}

const Paragraph: React.FC<ParagraphProps> = ({
  title,
  className,
  children,
}) => (
  <ParagraphContainer className={className}>
    {title && (
      <TitleContainer>
        <TopBorder />
        <H4>{title}</H4>
      </TitleContainer>
    )}
    <StyledParagraph>{children}</StyledParagraph>
  </ParagraphContainer>
)

const StyledCardsContainer = styled(CardsContainer)`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    grid-column: 2 / span 7;
  }
`

const OrganisationDevelopmentPage: React.FC<PageProps> = () => (
  <>
    <SEO
      title="Organisatieontwikkeling"
      description="Ontdek nu het groeipotentieel van jouw bedrijf door zowel de structuur, de mens in de organisatie en de cultuur te ontwikkelen. Zo kun je de interne en externe uitdagingen aan."
    />
    <GridContainer>
      <SimpleTitle>Organisatieontwikkeling</SimpleTitle>
      <PhotoHero
        title="Ontwerp &amp; advies"
        subtitle="Een organisatie is een sociaal systeem wat interacteert en streeft naar overleving. Eenvoudiger gezegd: een organisatie kan worden gezien als een doelgericht samenwerkingsverband. En precies in de laatste twee woorden kun je het onderscheid vinden tussen enerzijds de doelstellingen van een organisatie en anderzijds de organisatiestructuur die dit voor elkaar moet krijgen. Op het moment dat de doelstellingen niet helder zijn of de organisatiestructuur niet passend bij de doelstelling, ontstaan er problemen in een organisatie. Naast ambitieuze doelstellingen die je met de organisatie voor ogen hebt, is het belang van een medewerker die zich veilig en plezierig voelt, en die zich kan ontwikkelen minstens zo belangrijk. Om twee redenen: ten eerste omdat we vinden dat dit sowieso de morele plicht is van iedere organisatie, en ten tweede omdat gemotiveerde en gecommiteerde medewerkers veel harder werken en bereid zijn het beste van zichzelf te laten zien om de organisatiedoelen te behalen."
        image={
          <StaticImage
            src="../../images/organisatie.jpg"
            alt="Organisatieontwikkeling"
            placeholder="blurred"
            quality={100}
          />
        }
      />
      <ApproachContainer>
        <H2>Aanpak</H2>
        <Paragraph title="Onderzoek van de vraag">
          We starten bij de vraag: wat is de opdracht? Wat is de ambitie? Wat is
          het doel? Wat speelt er achter de vraag? Welke signalen neem je waar?
          Welke contextuele veranderingen zijn van invloed op de vraag? Je leest
          het al; we vragen jou en je team het hemd van het lijf. Realiseer je
          goed dat hier de bewustwording begint. Want stilstaan bij het nu is
          iets wat toch weinig in organisaties gebeurt. Afhankelijk van de vraag
          faciliteren we voor deze fase zogenaamde ‘vraag verkennende sessies’
          of interviewen we sleutelfiguren in de organisatie. In deze fase kun
          je niet om ons heen en zul je ons aantreffen in je bedrijf. We willen
          voelen, dichtbij zijn, de wandelgangen horen praten en tussen de
          regels door luisteren. Daarnaast bouwen we aan een vertrouwensrelatie
          om optimaal te kunnen samenwerken.
        </Paragraph>
        <Paragraph title="Diagnose van het nu">
          Op basis van deskresearch en de verzamelde informatie uit de vraag
          verkennende sessies en/of interviews stellen we een Ist-situatie
          (huidige situatie) op die we naast de Soll-situatie (toekomstige en
          wenselijke situatie) leggen. Deze GAP-analyse wordt uitgewerkt in een
          scenario die wij jullie presenteren. In deze fase trekken we ons even
          terug. We nemen afstand om te kunnen zien wat er nu is en wat er in
          potentie voor de organisatie ligt.
        </Paragraph>
        <Paragraph title="Draagvlak en besluiten">
          In een bijeenkomst met beslissers (en betrokkenen) bespreken we de
          scenario's en hoe die het best bijdragen aan succes. Commitment van
          alle sleutelfiguren is cruciaal voor succes. Afhankelijk van de
          complexiteit besteden we veel aandacht aan het vergroten van het
          draagvlak en het ambassadeurschap in de organisatie. We werken het
          scenario uit in een in een concrete strategie en een praktisch plan
          van aanpak die gericht zijn op het halen van gestelde Soll-situatie.
          Je ziet ons in deze fase weer veel en regelmatig. We zijn nabij om
          ja-knikkers die nee-doen vroegtijdig te signaleren en bij te staan.
          Weerstand is een signaal waar je goed naar zou moeten luisteren omdat
          het waardevolle informatie kan geven of omdat de weerstand van een
          sleutelfiguur een grotere groep in de organisatie kan representeren.
          Aandacht is in deze fase van groot belang.
        </Paragraph>
        <Paragraph title="Let’s go fase">
          Wat doen we dan? Soms niets omdat je tevreden bent met de stappen die
          zijn gezet en het advies wat is verkregen. Organisaties hebben echt
          niet bij ieder veranderproces hulp nodig van een externe adviseur.
          Heel vaak is verandering veel krachtiger als het wordt gedragen door
          de interne ambassadeurs. Wij juichen dit alleen maar toe.
        </Paragraph>
        <Paragraph>
          Soms is begeleiding wel prettig. Bijvoorbeeld als het gaat om complexe
          processen, veel onrust in de omgeving van de organisatie of een gebrek
          aan resources. De Beukelaar Groep ontwikkelt de begeleiding van een
          veranderproces op maat en we helpen je organisatie, afdeling of team
          met het implementeren van het herontwerp of de verandering.
        </Paragraph>
      </ApproachContainer>
      <StyledCardsContainer title="Bekijk ons aanbod:">
        <SecondaryCard title="Talentmanagement">
          <p>
            Mensen werken het beste, leren sneller, nemen initiatief en zijn het
            meest betrokken wanneer ze werk doen dat past bij hun drijfveren,
            talenten, cognitieve vaardigheden en competenties. Wanneer je als
            bedrijf aan de slag wil met talentmanagement dan draagt een passende
            structuur bij aan het succes. Zicht krijgen op de talenten van de
            medewerkers, de teamsamenstelling en de benodigde talenten voor een
            functie zijn een eerste stap in de goede richting. Echter, het
            doorvoeren van integraal talent vraagt meer. Nieuwsgierig naar wat?
            Neem <SecondaryLink to="/contact/">contact</SecondaryLink> met ons
            op voor een vrijblijvende informatiesessie.
          </p>
        </SecondaryCard>
        <SecondaryCard title="Ontwikkel Parade">
          <p>
            De Ontwikkel Parade is een krachtige organisatie interventie met als
            doel structuur- en cultuurelementen aan elkaar verbinden om zo een
            ware Boost te geven aan de ontwikkeling van de afdeling of
            organisatie. Deze ‘big-scale’ interventie kenmerkt zich door de
            diversiteit van verschijningsvormen. Iedere parade is anders en
            volledig op maat gemaakt. Voor een sfeerimpressie bekijk het filmpje
            hieronder.
          </p>
        </SecondaryCard>
        <VideoContainer
          title="De Beukelaar Groep - Ontwikkelparade"
          src="https://www.youtube.com/embed/jcPlNYIFZ5Q?autoplay=0&amp;mute=0"
        ></VideoContainer>
      </StyledCardsContainer>
      <NavigationDetailCTA>
        <PrimaryLink to="/diensten/teamontwikkeling/">
          NAAR TEAMONTWIKKELING
        </PrimaryLink>
        <PrimaryLink to="/diensten/leiderschap/">NAAR LEIDERSCHAP</PrimaryLink>
        <PrimaryLink to="/diensten/diversiteit-en-inclusie/">
          NAAR DIVERSITEIT &amp; INCLUSIE
        </PrimaryLink>
      </NavigationDetailCTA>
    </GridContainer>
  </>
)

export default OrganisationDevelopmentPage
